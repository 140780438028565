import { usePusher } from "@/composables/pusher";
import { computed, reactive, readonly } from "@vue/composition-api";

const { state: pusherState } = usePusher();

const state = reactive({
  conversation: null,
  conversationParties: {
    client: null,
    va: null,
  },
  messages: [],
  onlineUsers: [],
  parties:{}
});

const setConversation = (val) => {
  console.log('setConversation',val);
  state.conversation = val;
    state.conversationParties.client = val.client_conv;
    state.conversationParties.va = val.user;
    state.parties[val.client_conv.id]=val.client_conv
    state.parties[val.user.id]=val.user
    console.log("setConversation-state", state);
};

const resetConversation = () => {
  state.conversation = null;
  state.conversationParties.client = null;
  state.conversationParties.va = null;
  state.parties={}
}

const setMessages = (val) => (state.messages = val);
const addMessage = (val) => (state.messages = [...state.messages, val]);
const deleteMessage = (msgId) =>
  (state.messages = state.messages.filter((msg) => msg.id != msgId));

const updateOnlineUsers = () => {
  const channel = pusherState.pusher.channel(
    `presence-${state.conversation.id}`
  );
  const onlineUsers = [];

  channel.members.each((member) => {
    onlineUsers.push({
      id: member.info.id,
      name: member.info.name,
      type: member.info.type,
      profile_picture_url: member.info.profile_picture_url,
    });
  });

  state.onlineUsers = onlineUsers;
};

const activeConversation = computed(() => {
  console.log('activeConversation', state.conversation);
  if (!state.conversation) return false;
  return state.conversation;
  // if (Object.keys(state.conversation).length) return state.conversation;
  // return false;
});

export function useMessages() {
  return {
    state: readonly(state),

    setConversation,
    resetConversation,
    activeConversation,

    updateOnlineUsers,

    addMessage,
    setMessages,
    deleteMessage,
  };
}
