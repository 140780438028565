<template>
  <div class="d-flex flex-column col-12">
    <v-card class="chat-wrapper">
      <messages-log></messages-log>
      <!-- <send-message></send-message> -->
    </v-card>
  </div>
</template>

<script>
import MessagesLog from "@/components/conversations/chat/MessagesLog.vue";
import SendMessage from "@/components/conversations/chat/SendMessage.vue";

export default {
  name: "InboxMessages",
  components: {
    MessagesLog,
    // SendMessage,
  },
};
</script>

<style></style>
