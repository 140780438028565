<template>

  <li @mouseenter="state.isHovering = true" @mouseleave="state.isHovering = false" class="message-wrapper" :class="[state.isHovering ? 'message-hover' : '']">

    <div class="d-flex flex-column message-content message" :class="msgAlignment">

      <small class="message-content__sender" :class="msgAlignment">{{getUserName(msgData.send_by)}}</small>

      <p class="message-content__text" :class="[msgAlignment, msgArrowDirection]" v-if="messageComponent == 'text'"> {{ msgData.message }} </p>

      <p class="message-content__text" v-if="messageComponent == 'link'"> 
        <a :href="msgData.message" target="_blank" >{{ msgData.message }}</a>  
      </p>

      <div class="message-attachments" :class="msgAlignment" v-if="messageComponent == 'image'">
        <div class="image-attachments d-flex flex-row flex-wrap">
          <div class="image-attachment">
            <a :href="`${s3Url}${msgData.media_url}`" target="_blank">
              <img :src="`${s3Url}${msgData.media_url}`" alt="" loading="lazy" />
            </a>
          </div>
        </div>

        <a class="py-2 px-2 d-block mb-1" :href="`${s3Url}/${msgData.media_url}`" target="blank">
          {{ msgData.media_url }}
        </a>
      </div>

      <div class="audio-attachments" v-if="messageComponent == 'audio'" >
        <div class="audio-attachment">
          <audio :src="`${s3Url}${msgData.media_url}`" controls></audio>
        </div>
      </div>

      <div class="audio-attachments" v-if="messageComponent == 'video'" >
        <div class="audio-attachment">
          <audio :src="`${s3Url}${msgData.media_url}`" controls></audio>
        </div>
      </div>

      <!-- <div v-if="showControls" class="message-controls">
        <v-btn small icon tile elevation="2" color="warning" @click="state.isConfirmDeleteOpen = true">
          <v-icon small>{{ icons.mdiTrashCanOutline }}</v-icon>
        </v-btn>
      </div> -->

      <!-- <v-dialog width="fit-content" v-model="state.isConfirmDeleteOpen">
        <v-card>
          <v-card-title>Delete Message ?</v-card-title>
          <v-card-actions>
            <v-btn @click="deleteMsg" color="warning" outlined>Delete</v-btn>
            <v-btn @click="state.isConfirmDeleteOpen = false" outlined>Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->

      <small class="message-content__date" :class="msgAlignment">{{
        msgTime
      }}</small>
    </div>
  </li>
</template>

<script>
import axios from "@axios";
import { reactive,ref,computed, onMounted, nextTick } from "@vue/composition-api";
import { useUser } from "@/composables/user/user";
import { mdiTrashCanOutline } from "@mdi/js";
import { useMessages } from "@/composables/chat/messages";

export default {
  name: "ChatMessage",
  props: { msgData: Object },
  setup({ msgData }) {
    const { userId } = useUser();
    const { state: msgsState } = useMessages();
    const state = reactive({
      isHovering: false,
      isConfirmDeleteOpen: false,
    });
    const messageComponent = ref('')
    const s3Url = ref('https://s3.us-east-1.amazonaws.com/hireheroes-staging-chat-attachments/')

    onMounted(()=>{
      messageComponent.value = getComponentType(msgData.media_extension,msgData.type)
      console.log('messageComponent',messageComponent.value)
    })
 
    function getComponentType(extension,type='') {

    if (type == 'meeting') return 'link';

      const textExtensions = ['.txt', '.csv', '.log', '.rtf', '.doc', '.docx', '.odt'];
      const audioExtensions = ['.mp3', '.wav', '.ogg', '.aac'];
      const videoExtensions = ['.mp4', '.avi', '.mov', '.mkv'];
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];
      const documentExtensions = ['.pdf', '.ppt', '.pptx', '.xls', '.xlsx', '.ods', '.odp', '.odg'];

      if (extension == null) return 'text';

      if (textExtensions.includes(extension)) {
          return 'text';
      } else if (audioExtensions.includes(extension)) {
          return 'audio';
      } else if (videoExtensions.includes(extension)) {
          return 'video';
      } else if (imageExtensions.includes(extension)) {
          return 'image';
      } else if (documentExtensions.includes(extension)) {
          return 'file';
      } else {
          return 'File'; // fallback for other file types
      }
    }

    const getUserName = (id) => {
      let data = msgsState.parties[id]
      return `${data.first_name} ${data.last_name}`;
    }

    const showControls = computed(() => {
      if (state.isHovering && msgData.user_id == userId.value) return true;
      return false;
    });

    const resolveAlignment = () => {
      if (msgData.user_id == userId.value) return "our-message";
      return "their-message";
    };

    const resolveArrow = () => {
      if (msgData.user_id == userId.value) return "right-arrow";
      return "left-arrow";
    };

    const msgTime = () => {
      return new Date(msgData.created_at).toLocaleString();
    };

    const hasAttachments = () => {
      if (msgData.files) return true;
      return false;
    };

    const imageAttachments = () => {
      if (!msgData.files) return [];
      const attachments = JSON.parse(msgData.files);
      return attachments.filter((attachment) => attachment.type == "image");
    };

    const audioAttachments = () => {
      if (!msgData.files) return [];
      const attachments = JSON.parse(msgData.files);
      return attachments.filter((attachment) => attachment.type == "audio");
    };

    const normalAttachments = () => {
      if (!msgData.files) return [];
      const attachments = JSON.parse(msgData.files);
      return attachments.filter(
        (attachment) => attachment.type != "audio" && attachment.type != "image"
      );
    };

    const extractAttachments = () => {
      if (!msgData.files) return [];
      return JSON.parse(msgData.files);
    };

    async function deleteMsg() {
      try {
        await axios.delete(
          `/conversations/${msgsState.conversation.id}/messages/${msgData.id}`
        );
        state.isConfirmDeleteOpen = false;
      } catch (err) {
        console.log(err);
      }
    }

    return {
      s3Url,
      state,
      showControls,
      deleteMsg,
      msgAlignment: resolveAlignment(),
      msgArrowDirection: resolveArrow(),
      msgTime: msgTime(),
      messageComponent,
      hasAttachments: hasAttachments(),
      normalAttachments: normalAttachments(),
      imageAttachments: imageAttachments(),
      audioAttachments: audioAttachments(),
      msgAttachments: extractAttachments(),
      getUserName,
      icons: {
        mdiTrashCanOutline,
      },
    };
  },
};
</script>

<style lang="scss">
.message{
  max-width:80%
}

.message-content {
  width: fit-content;
  margin-bottom: 1rem;
}

.message-content__text {
  position: relative;
  background-color: #1f70fd;
  color: #fff;
  padding: 0.5rem 1rem;
  margin-bottom: 4px !important;
  border-radius: 6px;
  width: fit-content;
  word-break: break-all;
  white-space: pre-line;
}

.message-wrapper {
  position: relative;
  padding: 0.25rem 1rem;
}

.message-hover {
  background-color: #cccccca2;
}

.right-arrow::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0px;
  right: -5px;
  border-bottom: 10px solid #1f70fd;
  border-right: 10px solid transparent;
}

.left-arrow::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0px;
  left: -5px;
  border-bottom: 10px solid #1f70fd;
  border-left: 10px solid transparent;
}

.message-content__sender {
  margin-bottom: 0.15rem;
  width: fit-content;
}

.message-attachments {
  padding: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  border-radius: 6px;
  font-size: small;
  background-color: #f7f7f7;
  width: 200px;
}

.message-content__attachments {
  margin: 0.25rem 0rem;
  margin-left: auto;
  cursor: pointer;
  color: #1f70fd;
  display: inline-block;
}

.message-content__date {
  display: inline-block;
}

.image-attachment img {
  margin-right: 0.25rem;
  max-width: 100%;
  max-height: 75px;
}

.audio-attachment audio {
  max-width: 100%;
}

.message-controls {
  position: absolute;
  left: 10px;
}

.our-message {
  margin-left: auto;
}
</style>
