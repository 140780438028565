<template>
  <div>
    <div class="messages-wrapper">
      <ul class="messages-list" ref="msgsList">
        <p class="py-4 px-4" v-show="!msgsState.messages.length">
          No messages yet
        </p>
        <chat-message v-for="msg in msgsState.messages" :key="msg.id" :msgData="msg"></chat-message>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "@axios";
import ChatMessage from "@/components/conversations/chat/ChatMessage.vue";
import { usePusher } from "@/composables/pusher";
import { msgEvents } from "@/composables/event-listeners";
import { useMessages } from "@/composables/chat/messages";
import { mdiPhoneOutline } from "@mdi/js";
import { onMounted,reactive,ref,nextTick,onUnmounted } from "@vue/composition-api";

export default {
  name: "MessagesLog",
  components: {
    ChatMessage,
  },
  setup() {
    const { subscribeToChannel, unsubscribeFromChannel } = usePusher();
    const {
      activeConversation,
      setMessages,
      state: msgsState,
      readMsgs,
      unreadMsgs,
    } = useMessages();

    const msgsList = ref(null);

    const state = reactive({
      isLoading: false,
      msgText: "",
      realTimeCon: null,
    });

    function scrollToBottom() {
      msgsList.value.scrollTop = msgsList.value.scrollHeight;
    }

    async function fetchMessages(conversationId) {
      try {
        state.isLoading = true;
        const { data: messages } = await axios.get(
          `/chat/get-messages/${conversationId}`
        );
        console.log('messages:', messages.data.messages)
        setMessages(messages.data.messages);
        nextTick(() => scrollToBottom());
        subscribeToChannel(`presence-${conversationId}`, msgEvents);
      } catch (err) {
        console.log(err);
      } finally {
        state.isLoading = false;
      }
    }

    onMounted(() => fetchMessages(activeConversation.value.conversation_id));
    onUnmounted(() =>
      unsubscribeFromChannel(`presence-${activeConversation.value.conversation_id}`)
    );

    return {
      state,
      msgsState,
      msgsList,

      readMsgs,
      unreadMsgs,

      icons: {
        mdiPhoneOutline,
      },
    };
  },
};
</script>

<style lang="scss">
.messages-wrapper {
  height: 500px;
}

.messages-list {
  list-style: none;
  padding: 0px !important;
  margin: 0px;
  height: 100%;
  overflow-y: scroll;
}
</style>
